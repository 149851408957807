<template>
  <div class="app-container">
    <div style="width: 80%;margin: 0 auto;margin-top: 20px;" v-loading="listLoading">
      <el-form ref="form" :model="form" label-position="left" label-width="120px">
        <el-form-item label="送达时间">
          <el-time-picker size="small" v-model="time" value-format="HH:mm:00" placeholder="选择时间">
          </el-time-picker>
          <el-button icon="el-icon-plus" size="small" type="primary" @click="chooseTime" :disabled="!time"></el-button>
          <div>
            <el-tag :key="index" v-for="(item,index) in form.send_time" closable @close="handleCloseTag(index)" style="margin-right: 10px">
              {{item}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="集中时间">
          <el-time-picker is-range size="small" v-model="timeArr" value-format="HH:mm:00" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
          <el-button icon="el-icon-plus" size="small" type="primary" @click="chooseBusyTime" :disabled="!timeArr"></el-button>
          <div>
            <el-tag :key="index" v-for="(item,index) in form.busy_time" closable @close="handleCloseTagBusyTime(index)" style="margin-right: 10px">
              {{item[0]}} ~ {{item[1]}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveData" :loading="btnLoading">保存</el-button>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      listQuery: {
        school_id: "",
      },
      listLoading: true,
      btnLoading: false,
      form: {
        id: "",
        send_time: [],
        busy_time: [],
      },
      time: null,
      timeArr: null,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/sendSetting/info",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.form = response.data || {};
        this.listLoading = false;
      });
    },
    handleCloseTag(index) {
      this.form.send_time.splice(index, 1);
    },
    chooseTime() {
      if (this.time) {
        this.form.send_time.push(this.time);
        this.form.send_time.sort();
      }
    },
    handleCloseTagBusyTime(index) {
      this.form.busy_time.splice(index, 1);
    },
    chooseBusyTime() {
      if (this.timeArr) {
        this.form.busy_time.push(this.timeArr);
      }
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/sendSetting/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
